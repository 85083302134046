const str = window.location.href
let requestIp
// console.log(process.env)
//请求配置 判断是否为空气平台
if (str.indexOf('air') !== -1) {
  // requestIp = 'http://192.168.1.250:8787' //空气本地
  // requestIp = 'http://47.96.147.50:9777' //空气线上
  requestIp = 'https://airport.terabits.cn:9888'
} else if (str.indexOf('smoke') !== -1) {
  // requestIp = 'https://smoke.terabits.cn:8013'
  // requestIp = 'http://47.96.147.50:8013/zs/smoke/api/v1/statistics'// 控烟
  requestIp = 'https://smokeport.terabits.cn:8014/zs/smoke/api/v1/statistics'// 控烟
} else if (str.indexOf('water') !== -1) {
  requestIp = 'https://watersupervision.terabits.cn:9091'// 直饮水
} else if (str.indexOf('infant') !== -1) {
  if (window.location.origin ==='https://uatdatav.terabits.cn') {
    requestIp = 'https://uatchild.terabits.cn/api'
  }else if(window.location.origin ==="https://csdatav.terabits.cn"){
    requestIp = 'https://testchild.terabits.cn/api'
  }else{
    requestIp = 'https://child.terabits.cn/baby'// 婴幼儿
  }
  
} else {
  // requestIp = 'https://zjiva.terabits.cn:9091' // 正式
  // requestIp = 'http://47.110.230.84:3247/server-webmanager' // 测试
  // requestIp = 'https://zjapi.terabits.cn' // 新测试
  requestIp = process.env.VUE_APP_URL
}

// 判断测试或正式
// const environment = 'PE' //正式
// const environment = 'TE' //测试
// const environment = 'DE' // 新测试
const environment = process.env.NODE_ENV === 'development' ? 'DE' : 'PE'
console.log('>>>>ld');
export {
  requestIp,
  environment
}

